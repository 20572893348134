import {
	Card,
	CardImage,
	CardFooter,
	MediaCard,
	MetaBlock,
	Button as PlayButton,
} from '@elanco/component-library-v2'
import Link from 'next/link'
import type {Elements, IContentItem} from '@kontent-ai/delivery-sdk'
import Image from 'next/image'
import {useTrustArcCookieValue} from '@/utils/analytics'
import type {Block} from '@/_new-code/services/kontent-ai/types'
import {loadImageFromKontentAI} from '@/imageLoader'
import type {MetaBlockContentItem} from '../meta-block'
import type {WistiaContentItem} from '../videos/wistia'
import type {VideoBlockContentItem} from '../videos/video-block'
import {
	ReusableCTAButtonComponent,
	type CtaButtonContentItem,
} from '../cta-button'
import {getVideoDetails} from '../videos/video-types'

export type ResourceCardContentItem = IContentItem<{
	title: Elements.TextElement
	description: Elements.TextElement
	// Bad MaC not being camelCase!
	buttontext: Elements.TextElement
	metaBlock: Elements.LinkedItemsElement<MetaBlockContentItem>
	image: Elements.AssetsElement
	video: Elements.LinkedItemsElement<
		WistiaContentItem | VideoBlockContentItem
	>
	ctaButton: Elements.LinkedItemsElement<CtaButtonContentItem>
	hideFromLister: Elements.MultipleChoiceElement
	listerOrderNumber: Elements.NumberElement
	cookieFallbackImage: Elements.AssetsElement
}>

export const ResourceCardBlock: Block<ResourceCardContentItem> = ({
	block,
	...context
}) => {
	// Bad MaC not being camelCase!
	const buttonText = block.elements.buttontext || 'Play Video'
	const trustArcCookie = useTrustArcCookieValue()
	const hideVideo =
		trustArcCookie === '1:' || trustArcCookie === '0:' ? 'hide' : ''
	const video = block.elements.video[0]
		? getVideoDetails(block.elements.video[0])
		: null

	return (
		<>
			{block.elements.ctaButton.length > 0 ? (
				<Card
					LinkComponent={Link}
					className="flex h-full flex-col"
					title={block.elements.title}
				>
					{block.elements.image[0] ? (
						<CardImage
							alt={block.elements.image[0].description || ''}
							className="w-full  object-cover"
							src={block.elements.image[0].url}
						/>
					) : null}

					{block.elements.description !== '' && (
						<p className="mb-4 flex-grow">
							{block.elements.description}
						</p>
					)}

					<CardFooter>
						{block.elements.ctaButton[0] ? (
							<ReusableCTAButtonComponent
								block={block.elements.ctaButton[0]}
								{...context}
								variant="primary"
							/>
						) : null}
					</CardFooter>
				</Card>
			) : (
				<MediaCard
					className="h-full"
					metaBlock={
						<MetaBlock blur>
							{block.elements.metaBlock[0]?.elements.title || ''}
						</MetaBlock>
					}
					title={block.elements.title}
					videoId={hideVideo === 'hide' ? '' : video?.videoId}
					videoPosterAltText={
						block.elements.image[0]?.description || ''
					}
					videoPosterFrameUrl={
						hideVideo === 'hide' ? '' : block.elements.image[0]?.url
					}
					videoType={video?.type}
				>
					{hideVideo === 'hide' &&
						(block.elements.cookieFallbackImage[0]?.url ? (
							<div className="mb-4 mt-10 h-48 w-full flex-shrink-0">
								<Image
									alt={
										block.elements.cookieFallbackImage[0]
											?.description || ''
									}
									className="object-contain"
									height={192}
									loader={loadImageFromKontentAI}
									src={
										block.elements.cookieFallbackImage[0]
											?.url
									}
									width={336}
								/>
							</div>
						) : (
							<div className="mb-4 mt-10 h-48 w-full bg-blue-900" />
						))}
					{block.elements.description !== '' && (
						<p>{block.elements.description}</p>
					)}
					{hideVideo === 'hide' ? (
						''
					) : (
						<PlayButton className="w-full">{buttonText}</PlayButton>
					)}
				</MediaCard>
			)}
		</>
	)
}
